<template>
  <a-modal
      v-drag-modal
      title="选择公司"
      :visible="visible"
      :maskClosable="false"
      :width="1200"
      :height="700"
      @ok="handleOk"
      @cancel="visible = false"
  >
    <div style="padding:10px;">
      <div class="search">
        <!-- 搜索区域 -->
        <div class="items" style="flex: none">
          <div class="one-item" style="width: 200px">
            <a-input
                class="input"
                v-model="queryParams.company"
                placeholder="公司名称"
                allowClear
            />
          </div>
          <div class="one-item" style="width: 200px">
            <a-input
                class="input"
                v-model="queryParams.bizCreditNo"
                placeholder="统一编码"
                allowClear
            />
          </div>
          <div class="one-item" style="width: 200px">
            <a-input
                class="input"
                v-model="queryParams.legalPerson"
                placeholder="法人姓名"
                allowClear
            />
          </div>
        </div>
        <div class="operator">
          <a-button type="primary" @click="search(1)">查询</a-button>
          <a-button style="margin-left: 8px" @click="reset">重置</a-button>
        </div>
      </div>
    </div>
    <a-table ref="TableInfo" size="small" :bordered="true"
             :columns="columns"
             rowKey="id"
             :dataSource="dataSource"
             :pagination="pagination"
             @change="handleTableChange"
             :loading="loading"
             :scroll="{y:400,x:600}">
      <template slot="topicOptions" slot-scope="text, record">
        <div v-for="item in record.optionsList" :key="item.index">
          {{ `${item.index}->选项【${item.option}】，分数（${item.score}）` }}
        </div>
      </template>
      <template slot="operation" slot-scope="text, record">
        <a-button type="primary" size="small" style="margin-right:15px;"  @click="selectItem(record)">选择
        </a-button>
      </template>
    </a-table>
  </a-modal>
</template>

<script>
import {ListMixin} from "@/mixin/list-mixin";

export default {
  name: "SelectCompanyModal",
  props: {},
  mixins: [ListMixin],
  data() {
    return {
      listGetUrl: "company/listByAdmin",
      visible: false,
      selected: undefined
    }
  },
  computed: {
    columns() {
      let {sortedInfo, filteredInfo} = this;
      sortedInfo = sortedInfo || {};
      filteredInfo = filteredInfo || {};
      return [
        {
          title: 'ID',
          width: 80,
          dataIndex: 'id',
          align: 'center',
        },
        {
          title: '公司名称',
          width: 200,
          dataIndex: 'company',
          align: 'center',
        },
        {
          title: '统一编码',
          align: 'center',
          width: 200,
          dataIndex: 'bizCreditNo',
        },
        {
          title: '注册类型',
          align: 'center',
          width: 100,
          dataIndex: 'regType',
        },
        {
          title: '法人姓名',
          dataIndex: 'legalPerson',
          align: 'center',
          width: 80,
        },
        {
          title: '省市区',
          align: 'center',
          dataIndex: 'province',
          width: 150,
          customRender: (text, row, index) => {
            return (row.province||'')+ (row.city||'')+ (row.area||'')
          }
        },
        {
          title: '地址',
          dataIndex: 'address',
          ellipsis: true,
          width: 200,
        },{
          title: '操作',
          width: 100,
          align: 'center',
          // fixed: 'right',
          dataIndex: 'operation',
          scopedSlots: {customRender: 'operation'}
        }]
    },
  },
  mounted() {
    this.search(1);
  },
  methods: {
    showModal() {
      this.selected = []
      this.visible = true
    },
    handleOk() {
      this.visible = false;
    },

    selectItem(item) {
      this.visible = false;
      this.$emit("onSelected",item);
    }
  }

}
</script>

<style scoped lang="less">

.step_box {
  margin: 0px auto;
  border: 1px #E5E5E5 solid;


  .step_box_head {
    border-bottom: 1px #E5E5E5 solid;
    color: #666;
    font-size: 16px;
    font-weight: 100;
    padding: 15px 40px 15px 0;
    background: #F5F5F5;
  }

  .step_menu {
    ul li {
      float: left;
      margin-right: 20px;
      border: 0 none;
      list-style: none;
      cursor: pointer;
    }

    ul li span {
      display: block;
      background: url(/static/images/step_menu.png) left bottom no-repeat;
      font-size: 14px;
      width: 146px;
      height: 35px;
      line-height: 35px;
      padding-left: 30px;
    }

    ul li.checked span {
      background: url(/static/images/step_menu.png) left top no-repeat;
      color: #FFF;
      border: 0 none;
    }
  }
}

/deep/ .ant-modal-body {
  padding: 0 0px;
}
</style>
